<template>
    <span :class="{ inverted }" class="logo">

        <!--Here we have the logo for pages while browsing in various Lucid tabs -> lucid black logo -->
        <img
            src="/black_logo_icon.png"
            class="logo-icon"
            :alt="
                configStore.logoTextVisible && !hideText
                    ? undefined
                    : 'Lucid'
            "
        />
        <!-- NOTE: update logic here with full lucid words in the future
        <img
            v-if="configStore.logoTextVisible && !hideText"
            src="/logo-text.png"
            class="logo-text"
            alt="Lucid"
        />
        -->
    </span>
</template>

<script setup lang="ts">
import { useStore } from '~/store/store';

defineProps<{
    inverted?: boolean;
    hideText?: boolean;
}>();

const configStore = useStore();
</script>

<style scoped lang="scss">
@import './logo-style.scss';
</style>
