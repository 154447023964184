<template>
    <component
        :is="standalone ? 'header' : QHeader"
        v-if="!isMobileApp"
        class="text-primary pad-x-4rem main-header bg-black dark"
        :class="{
            'bg-white no-dark': dark === false
        }"
    >
        <!-- Invert the logic :class="[dark ? 'bg-black' : 'bg-white', { dark }]" -->
        <q-toolbar class="q-px-none q-py-md row justify-between">
            <nav class="nav-menu q-px-none">

                <!-- isolated hide logo removed from below v=if [ !isMobileApp && ]-->
                <div
                    v-if="authStore.isAuthenticated"
                    class="signup-logo-container"
                >
                    <NuxtLink :to="logoLinkUrl" @click="menuOpen = false">
                        <Logo
                            :inverted="menuOpen || !!dark"
                            :hide-text="minimal"
                        />
                    </NuxtLink>
                    <div
                        v-if="showSelectLogo || partner"
                        class="partner"
                        :class="{ black: !dark }"
                    >
                        <div>//</div>
                        <img
                            v-if="partner === 'SELECT' || showSelectLogo"
                            src="/SELECT-logo.png"
                            alt="SELECT"
                            :style="dark ? {} : { filter: 'invert(1)' }"
                        />
                        <BaseClub v-else class="base-club" />
                    </div>
                </div>
                <!-- isolated hide removed from below v=if [ !isMobileApp ]-->
                <div v-else class="signup-logo-container">
                    <a href="https://www.watchlucid.com">
                        <Logo :inverted="!!dark" />
                    </a>
                    <div v-if="partner" class="partner">
                        <div>//</div>
                        <img
                            v-if="partner === 'SELECT'"
                            src="/SELECT-logo.png"
                            alt="SELECT"
                        />
                        <BaseClub v-else class="base-club" />
                    </div>
                </div>
            </nav>

            <!-- isolated hide removed from below v=if [ !isMobileApp && ]-->
            <div
                v-if="authStore.isAuthenticated"
                class="row text-right"
            >
                <div class="links">
                    <template v-if="$q.screen.gt.xs">
                        <NuxtLink
                            v-if="!$route.fullPath.includes('/collections')"
                            class="text-link"
                            :to="txtLink.starter.collections"
                        >
                            <span>Collections</span>
                        </NuxtLink>
                        <NuxtLink
                            class="text-link"
                            :to="txtLink.starter.ghostStart"
                        >
                            <span>Home</span>
                        </NuxtLink>
                    </template>
                    <NuxtLink class="text-link" :to="txtLink.starter.search">
                        <QIcon
                            name="mdi-magnify"
                            size="32px"
                            class="search-icon"
                        />
                    </NuxtLink>
                </div>
                <HeaderSettings 
                    :menu-open="menuOpen"
                    @toggle="menuOpen = !menuOpen"
                />
            </div>
        </q-toolbar>
    </component>
</template>

<script setup lang="ts">
import txtLink from 'public/text/link';
import { useAuthStore } from '@/store/auth';
import { QHeader } from 'quasar';
import BaseClub from '~/assets/icons/base_club.svg';
import type { Partner } from '~/types/product';

defineProps<{
    dark?: boolean;
    partner?: Partner;
    direct?: boolean;
    minimal?: boolean;
    standalone?: boolean;
}>();


const isMobileApp = checkIfMobileApp();

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
router.afterEach(() => {
    menuOpen.value = false;
});

const showSelectLogo = computed<boolean>(() => {
    return (
        authStore.isAuthenticated &&
        authStore.userData?.partnerSource === 'SELECT' &&
        router.currentRoute.value.path.startsWith('/my-account')
    );
});

const logoLinkUrl = computed(() => {
    if (/^\/(?:browse|ghost|auto)\/?$/.test(route.fullPath)) {
        return txtLink.starter.ghostStart;
    }
    if (route.fullPath.startsWith('/auto/')) {
        return txtLink.starter.speedPlay;
    }
    return txtLink.starter.home;
});

const menuOpen = ref(false);
</script>

<style lang="scss" scoped>
@import '~/public/assets/variables';  // Make sure this is at the top
@import '/public/assets/mixins';
@import '/public/assets/common';

.pad-x-4rem {
    padding-left: 2rem;
    padding-right: 2rem;

    @media (min-width: $breakpoint-large) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.main-header {
    transition: 0.2s background-color;
}

.nav-menu {
    z-index: 10;
    transition: all 0.05s ease;

    display: flex;
    align-items: center;

    @media (min-height: 600px) {
        padding: $spacing1 0px;
    }
    & > * {
        @include breakpoints-up(sm) {
            margin: 0 $spacing1;
        }
    }
    & a {
        text-decoration: none;
    }
}

.signup-logo-container {
    display: flex;
    align-items: center;
}

.partner {
    display: flex;
    align-items: center;
    color: white;
    &.black {
        color: black;
    }
    div {
        font-size: 20px;
        margin: 0 10px 5px;
    }
    img {
        width: 100px;
        margin-left: 10px;
    }
}

.base-club {
    width: 40px;
    height: 40px;
}

@media (min-width: $breakpoint-xsmall) {
    .partner img {
        width: 150px;
    }
}

@media (min-width: $breakpoint-medium) {
    .partner {
        div {
            font-size: 40px;
            margin: 0 20px 10px;
        }
        img {
            width: 200px;
        }
    }
    .base-club {
        width: 60px;
        height: 60px;
    }
}

.links {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.text-link {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin: {
        left: 10px;
        right: 10px;
    }
    font-weight: bold;
    color: var(--q-secondary);
  /*  &:hover {                         // TODO let's put this in sometime but I cant get to work
        color: v-bind('dark === false ? "#{$black}" : "#{$lucid-off-white}"');
    } */
}
</style>
